import { ColorPalette } from "./type/ColorPalette";

const sadnessColor: ColorPalette = {
  lighten: "#69c1f7",
  light: "#39adf4",
  main: "#0d98ed",
  dark: "#0a79bd",
  darken: "#085a8c",
};

const happinessColor: ColorPalette = {
  lighten: "#b5dfb7",
  light: "#92cf94",
  main: "#6ec071",
  dark: "#4caf50",
  darken: "#3d8b40",
};
const panicColor: ColorPalette = {
  lighten: "#e3b5db",
  light: "#c86ab7",
  main: "#953784",
  dark: "#702963",
  darken: "#4b1b42",
};

// const panicColor: ColorPalette = {
//   lighten: "#ffedcc",
//   light: "#ffdb99",
//   main: "#ffc966",
//   dark: "#ffb733",
//   darken: "#ffa500",
// };

const angrinessColor: ColorPalette = {
  lighten: "#ff9595",
  light: "#ff6262",
  main: "#ff2f2f",
  dark: "#fb0000",
  darken: "#c80000",
};
const defaultColor: ColorPalette = {
  lighten: "#ababab",
  light: "#787878",
  main: "#454545",
  dark: "#121212",
  darken: "#000",
};
export {
  sadnessColor,
  happinessColor,
  panicColor,
  angrinessColor,
  defaultColor,
};
